import { Space } from 'antd';
import React from 'react';
import Styles from './Footer.css';
import { Breadcrumb } from 'antd';
export default function NxFooterTheme1({
    companyName = '© 2021 Altascio Technologies Inc.',
    className = '',
    onTermsClick = () => {

    },
    onNxappClick = () => {

    },
    ExtraContent = []
}) {
    let { Item } = Breadcrumb;
    return (
        <div className={`${Styles.footer} ${className}`}>
            <div className={`${Styles.inname}`}>
                <span> {companyName} </span>
            </div>
            <div className={`${Styles.inname2}`}>
                {/* <span> {companyName} </span> */}
                <Breadcrumb separator="|">
                    {ExtraContent.map((item, index) => {
                        return <Item onClick={item.onClick} key={index} separator="|" >
                            <a> {item.name} </a>
                        </Item>
                    })}
                </Breadcrumb>
            </div>
            {/* {ExtraContent.map((item, index) => <span key={index} onClick={item.onClick} className={Styles.points}> {item.name} </span>)} */}
        </div>
    )
}