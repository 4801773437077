import React from 'react';
import Styles from './Card.css';

export default function CardTheme1({
    className = '',
    datas = [{
        title: "",
        data: [{ image: '', heding: '', info: '', onClick: () => { } }]
    }]
}) {
    return (
        <div className={`container1 ${className}`}>
            {datas.map((item1, i) => <div key={i} className={Styles.nxcard} style={{marginBottom: 20}}>
                <h6 className="fw-bold">{item1.title}</h6>
                <div className={Styles.nxcardbody}>
                    <div className={`row ${Styles.nxpartneroperations}`}>
                        {item1.data.map((item, index) => <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-3">
                            <div className={`card ${Styles.hov}`} onClick={item.onClick}>
                                <div className={`card-body ${Styles.cardflex}`}>
                                    <div className={Styles.nxserviceimg}>
                                        <img src={item.image} width="30" />
                                    </div>
                                    <div className={`${Styles.nxservicetitles} text-start`}>
                                        <label>{item.heding}</label>
                                        <div className={Styles.nxserviceinfo}>
                                            <p>{item.info}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>)}
        </div>
    )

}