import React, { useEffect, useState } from 'react';
import Styles from './Grid.css';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
// import {ReactComponent as Close} from '../../Configs/Assets/close.svg'

export default function NxGridListT1({
    header = [
        {
            name: '',
            sort: false,
            search: false,
        }
    ],
    data = [
        {
            sendItem: '',
            data: ['']
        }
    ],
    className = '',
    rowClick = (e) => { },
    onSortClick = (e) => { },
    onChangeText = (e) => { },
    searchValue = '',
    placeholder = 'Search...',
    enableSearch = true,
    showShowing = true,
    enableAutoPagination = true,
    autoSearch = false,
}) {
    const [datas, setDatas] = useState();
    const [sVal, setSval] = useState();
    const [rows, setRow] = useState(data);
    const [sortsArr, setSortsArr] = useState({}) // true for assending and false for desending
    const [totalPages, setTotalPage] = useState([]);
    const [showPage, setShowPage] = useState(false);
    const [pageVal, setPageVal] = useState(1);
    const [limit, setLimit] = useState(10);
    const [tp, setTp] = useState(1);

    function sortTable(index, vals) {
        let srt = rows;
        rows.sort((a, b) => {
            if (a.data[index] < b.data[index]) {
                if (vals == true) {
                    return -1;
                } else {
                    return 1
                }
            }
            if (a.data[index] > b.data[index]) {
                if (vals == true) {
                    return 1;
                } else {
                    return -1;
                }
            }
            return 0;
        });
        rowChanceFunction(true, rows, limit)
        setRow(rows);
        // setDatas(val)
    }
    useEffect(() => {
        setRow(data);
    }, [data]);


    const rowChanceFunction = (data, rew, lim) => {
        let pagest = false;
        if (data && data == true) {
            if (rew.length <= lim) {
                setShowPage(false)
            } else {
                pagest = true;
                let abs = rew.length / parseInt(lim);
                let act = Math.ceil(abs);
                let varl = [];
                for (let index = 1; index <= act; index++) {
                    varl.push(index);
                    varl.push()
                }
                setTp(act)
                setTotalPage(varl);
                setShowPage(true);
            }
            setPageVal(1)
            let val = rew.map((item, i) => {
                if (pagest = true) {
                    if (i < pageVal * lim) {
                        return <tr key={i} className={Styles.tabledata} onClick={() => {
                            rowClick(item.sendItem)
                        }}>
                            {item.data.map((it, ind) => {
                                return <td key={ind} className={Styles.tabledatas}>{it}</td>
                            }
                            )}
                        </tr>
                    }
                } else {
                    return <tr key={i} className={Styles.tabledata} onClick={() => {
                        rowClick(item.sendItem)
                    }}>
                        {item.data.map((it, ind) => {
                            return <td key={ind} className={Styles.tabledatas}>{it}</td>
                        }
                        )}
                    </tr>
                }
            })
            setDatas(val)
        } else {
            if (rows.length <= limit) {
                setShowPage(false)
            } else {
                pagest = true;
                let abs = rows.length / parseInt(limit);
                let act = Math.ceil(abs);
                let varl = [];
                for (let index = 1; index <= act; index++) {
                    varl.push(index);
                    varl.push()
                }
                setTp(abs)
                setTotalPage(varl);
                setShowPage(true);
            }
            setPageVal(1)
            let val = rows.map((item, i) => {
                if (pagest = true) {
                    if (i < pageVal * limit) {
                        return <tr key={i} className={Styles.tabledata} onClick={() => {
                            rowClick(item.sendItem)
                        }}>
                            {item.data.map((it, ind) => {
                                return <td key={ind} className={Styles.tabledatas}>{it}</td>
                            }
                            )}
                        </tr>
                    }
                } else {
                    return <tr key={i} className={Styles.tabledata} onClick={() => {
                        rowClick(item.sendItem)
                    }}>
                        {item.data.map((it, ind) => {
                            return <td key={ind} className={Styles.tabledatas}>{it}</td>
                        }
                        )}
                    </tr>
                }
            })
            setDatas(val)
        }
    }
    useEffect(() => {
        rowChanceFunction(false, [], limit)
    }, [rows]);
    const onNext = () => {
        if (tp == pageVal) {

        } else {
            let page = pageVal + 1;
            setPageVal(page)
            let val = rows.map((item, i) => {
                if ((i >= (page - 1) * limit) && (i < page * limit)) {
                    return <tr key={i} className={Styles.tabledata} onClick={() => {
                        rowClick(item.sendItem)
                    }}>
                        {item.data.map((it, ind) => {
                            return <td key={ind} className={Styles.tabledatas}>{it}</td>
                        }
                        )}
                    </tr>
                }
            })
            setDatas(val)
        }
    }
    const onPrivew = () => {
        if (pageVal == 1) {

        } else {
            let page = pageVal - 1;
            setPageVal(page)
            let val = rows.map((item, i) => {
                if ((i >= (page - 1) * limit) && (i < page * limit)) {
                    return <tr key={i} className={Styles.tabledata} onClick={() => {
                        rowClick(item.sendItem)
                    }}>
                        {item.data.map((it, ind) => {
                            return <td key={ind} className={Styles.tabledatas}>{it}</td>
                        }
                        )}
                    </tr>
                }
            })
            setDatas(val)
        }
    }
    const onPageChange = (page) => {
        setPageVal(page)
        let val = rows.map((item, i) => {
            if ((i >= (page - 1) * limit) && (i < page * limit)) {
                return <tr key={i} className={Styles.tabledata} onClick={() => {
                    rowClick(item.sendItem)
                }}>
                    {item.data.map((it, ind) => {
                        return <td key={ind} className={Styles.tabledatas}>{it}</td>
                    }
                    )}
                </tr>
            }
        })
        setDatas(val)
    }

    const onLimitChange = (val) => {
        setLimit(parseInt(val));
        rowChanceFunction(true, rows, parseInt(val));
    }

    return (
        <div className={`${Styles.gridlist} card table-responsive table-wrapper-scroll-y my-custom-scrollbar ${className}`}>
            <div className="card-body">
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                    {enableAutoPagination == true ? <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Show </span>
                        <div className='mx-1'>
                            <select className="form-select" aria-label="Default select example" onChange={(e) => {
                                onLimitChange(e.target.value);
                            }} value={limit}>
                                <option value='10'>10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <span>entries</span>
                    </div> : <div></div>}
                    {enableSearch == true && <div className=''>
                        {autoSearch == false && <input type='text' className={`${Styles.inpsty}`} placeholder={placeholder} onChange={(e) => onChangeText(e.target.value)} value={searchValue} />}
                        {autoSearch == true && <input type='text' className={`${Styles.inpsty}`} placeholder={placeholder} onChange={(e) => {
                            setSval(e.target.value)
                            var filteredValues;
                            if (e.target.value) {
                                let ars = [];
                                for (let i = 0; i < header.length; i++) {
                                    const element = header[i];
                                    if (element.search == true) {
                                        ars.push(i)
                                    }
                                }
                                filteredValues = data.filter(o => {
                                    let vs = o.data;
                                    for (let val of ars) {
                                        let vals = o.data[val].toLocaleLowerCase()
                                        if (vals.search(e.target.value.toLocaleLowerCase()) != -1)
                                            return o;
                                    }
                                });
                                // console.log(filteredValues)
                                // filteredValues = data.filter(o => Object.entries(o).some(
                                //     prop => String(prop).toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
                                // ));
                            }
                            else {
                                filteredValues = data;
                            }
                            setRow(filteredValues);
                        }} value={sVal} />}
                    </div>}

                </div>
                <table id="table-nxs" className="table table-hover mt-3" >
                    <thead>
                        <tr className={Styles.thds}>
                            {header.map((item, i) => {
                                return <th key={i} scope="col" className={Styles.tableheader}>
                                    <div className={Styles.nxdivtableheader}>
                                        <span>{item.name}</span> {item.sort == true && <span className={Styles.sort} onClick={() => {
                                            // onSortClick(item.name)
                                            let vals;
                                            if (!sortsArr[i]) {
                                                setSortsArr({ [i]: true });
                                                vals = true
                                            } else {
                                                if (sortsArr[i] == true) {
                                                    setSortsArr({ [i]: false });
                                                    vals = false
                                                } else {
                                                    setSortsArr({ [i]: true });
                                                    vals = true
                                                }
                                            }
                                            sortTable(i, vals)
                                        }}>
                                            {/* <img src={sort} className={Styles.nxtablesorticon} width="15" /> */}
                                            <div className={Styles.nxtablesorticon}>
                                                <BsArrowUp style={{ marginRight: -3 }} color={sortsArr && sortsArr[i] == false ? 'grey' : sortsArr[i] == true ? 'black' : 'grey'} size='15' />
                                                <BsArrowDown style={{ marginLeft: -3 }} color={sortsArr && sortsArr[i] == true ? 'grey' : sortsArr[i] == false ? 'black' : 'grey'} size='15' />
                                            </div>
                                            {/* <Sort className={Styles.nxtablesorticon} width="4" height="4" /> */}
                                        </span>}
                                    </div>
                                </th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {datas}
                    </tbody>
                </table>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', padding: 10 }}>
                    {(enableAutoPagination == true && showShowing == true) ? <div>
                        <span>Showing {((pageVal - 1) * limit) + 1} to {pageVal * limit} of {rows.length} entries</span>
                    </div> : <div></div>}
                    {(enableAutoPagination == true && showPage == true) && <div>
                        <ul className="pagination">
                            <li className="page-item" onClick={onPrivew}>
                                <a className="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span className="sr-only">Previous</span>
                                </a>
                            </li>
                            {totalPages.map((item, index) => {
                                return <li key={index} style={{ cursor: 'pointer' }} className={pageVal == item ? "page-item active" : "page-item"} onClick={() => onPageChange(item)}><span className="page-link"><span style={{ color: pageVal == item ? '#fff' : '#000' }}>{item}</span> </span></li>
                            })}
                            <li className="page-item" onClick={onNext}>
                                <a className="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </div>}
                </div>
            </div>
        </div >
    )
}