import React from 'react';
import Styles from './ActionTab.css';
import { faPlus, faArrowDown, faColumns, faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
let dataParams = [{
    name: "",
    discription: "",
    icon: () => { return <div /> },
    onClick: () => { },
    divideline: false,
}]


export default function NxActionTab({
    enableNewEntry = false,
    onNewEntryClick = () => { },
    onColumnsClick = () => { },
    onRefreshClick = () => { },
    onDownloadClick = () => { },
    className = '',
    exportValue = [],
    fileName = '',
    data = [] }) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        <div className={`${Styles.actiontab} ${className}`}>
            <ul className={`${Styles.datafleld} text-left`}>
                {enableNewEntry == true && <li title={'New Entry'} onClick={onNewEntryClick}>
                    <div className={Styles.datavalue}>
                        <FontAwesomeIcon icon={faPlus} color="#039be5" size='lg' />
                        <span className={Styles.text}>New Entry</span>
                    </div>
                </li>}
                <li title={'Downloads'} onClick={() => { exportToCSV(exportValue, fileName) }}>
                    <div className={Styles.datavalue}>
                        <FontAwesomeIcon icon={faArrowDown} color="#039be5" size='lg' />
                        <span className={Styles.text}>Downloads</span>
                    </div>
                </li>

                {/* Refresh */}
                <li title={'Refresh'} onClick={onRefreshClick}>
                    <div className={Styles.datavalue}>
                        <FontAwesomeIcon icon={faRedo} color="#039be5" size='lg' />
                        <span className={Styles.text}>Refresh</span>
                    </div>
                </li>

                {/* Columns */}
                {/* <div className={Styles.divideline}> {"|"} </div>
                <li title={'Columns'} onClick={onColumnsClick}>
                    <div className={Styles.datavalue}>
                        <FontAwesomeIcon icon={faColumns} color="#039be5" size='lg' />
                        <span className={Styles.text}>Columns</span>
                    </div>
                </li>
                <div className={Styles.divideline}> | </div> */}

                {data.length > 0 && data.map((item, index) => {
                    // console.log(typeof(item.icon))
                    return <div className='da'>
                        {(item.divideline && item.divideline == true) && <div className={Styles.divideline}> {"|"} </div>}
                        <li title={item.discription ? item.discription : ''} onClick={item.onClick}>
                            <div className={Styles.datavalue}>
                                {typeof (item.icon) != undefined ? item.icon() : ""}
                                <span className={Styles.text}>{item.name} </span>
                            </div>
                        </li>
                        {(item.divideline && item.divideline == true) && <div className={Styles.divideline}> | </div>}
                    </div>

                })}
            </ul>
        </div>
    )
}
