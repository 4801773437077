import React from 'react';
import Styles from './TitleTab.css';
import { ReactComponent as Close } from '../../../Configs/Assets/close.svg'

export default function NxTitleTab({
    title = '',
    onCloseClick = () => { },
    className = '',
    enableClose = true,
    contentClass = '',
    leftSideClass = '',
    contentData = [
        {
            Content: () => {
                return <div />
            }
        },
    ]
}) {
    return (
        <div className={`${Styles.titletab} ${className}`}>
            <span className={Styles.title}>{title}</span>
            <div className={leftSideClass}>
                <div className={contentClass}>
                    {contentData.map((item, index) => {
                        return <item.Content />
                    })}
                </div>
                {enableClose == true && <div className={Styles.iconstyle} onClick={onCloseClick}>
                    <Close className={Styles.imgs} />
                </div>}
            </div>
        </div>
    )
}