import React,{useState} from 'react';
import Styles from './Header.css';
// import { faBars, faUserCircle, faBell } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Configs } from '../../../Configs';

export default function NxHeader({
    title = Configs.companyName,
    email = '',
    DrawerIcon = () => {return <div />},
    PortalHerderTitle = () => {return <div />},
    className = '',
    onNotifyClick = () => { },
    onAvatarClick = () => { },
    rightElements=[ { elements: () => {return <div /> }} ],
}) {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
      };
    const onClose = () => {
      setVisible(false);
    };
    return (
        <div>
            <div className={`${Styles.topheader} ${className}`}>
                <div className={`row ${Styles.contain}`}>
                    <div className={`col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 ${Styles.leftheader}`}>
                        <div className={`${Styles.icns} text-center`}  onClick={showDrawer} >
                            {/* <FontAwesomeIcon icon={faBars} color="white" size="sm" /> */}
                            <DrawerIcon />
                        </div>
                        <PortalHerderTitle />
                        {/* <label className={`${Styles.portalname}`}> {title} </label> */}
                    </div>

                    <div className={`col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 ${Styles.rightheader}`}>
                        {rightElements.map((item, index) => {
                            return <div key={index} className={`${Styles.icns}`}>
                                {item.elements()}
                            </div>
                        })}


                        {/* <div class={Styles.dropdownmenu} aria-labelledby="dropdownMenuOffset">
                            <div className={Styles.nxavatar}>
                                <div className={Styles.nxname}>
                                    <p>{Configs.companyName}</p>
                                </div>
                                <div className={Styles.nxlogout}>
                                    <p> Signout</p>
                                </div>
                            </div>

                            <div className={`${Styles.nxavataraccount} mt-2 mb-3`}>
                                <div className={Styles.nxavatarimg}>
                                    <div>DD</div>
                                </div>
                                <div className={Styles.nxavatarinfo}>
                                    <label>Demo one</label>
                                    <p>demo@mail.com</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={`${Styles.pos}`}>
                -
            </div>
        </div>
    )
}
