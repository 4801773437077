import React from 'react';
import Styles from './TitleTab.css';
import { ReactComponent as Close } from '../../../Configs/Assets/close.svg'
import { Content } from 'antd/lib/layout/layout';

export default function TitleTabTheme1({
    title = '',
    onCloseClick = () => { },
    className = '',
    enableClose = true,
    contentClass = '',
    leftSideClass = '',
    contentData = [
        {
            Content: () => {
                return <div />
            }
        },
    ]
}) {
    return (
        <div className={`${Styles.titleHeadcomp}`}>
            <div className={`${Styles.titletab} ${className}`}>
                <span className={Styles.title}>{title}</span>
                <div className={`${Styles.titleMain} ${leftSideClass}`}>
                    <div className={`${Styles.titleMain} ${contentClass}`}>
                        {contentData.map((item, index) => {
                            return <item.Content key={index} />
                        })}
                    </div>
                    {enableClose == true && <div className={Styles.iconstyle} onClick={onCloseClick}>
                        <Close className={Styles.imgs} />
                    </div>}
                </div>
            </div>
            <hr />
        </div>
    )
}