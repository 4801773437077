import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';

import NxCustomTabs from './Components/CustomTabs/CustomTabsDefaults/CustomTabs';
import NxCustomTabsTheme1 from './Components/CustomTabs/CustomTabsTheme1/CustomTabs';

import NxBreadcrumbs from './Components/BreadCrumb/BreadCrumbDefault/BreadCrumb';
import BreadCrumbTheme1 from './Components/BreadCrumb/BreadCrumbTheme1/BreadCrumb';

import NxFooters from './Components/Footer/FooterDefault/Footer';
import NxFooterTheme1 from './Components/Footer/FooterTheme1/Footer';

import NxHeaders from './Components/Header/HeaderDefault/Header';
import NxHeaderTheme1 from './Components/Header/HeaderTheme1/Header';

import NxLoaders from './Components/Loader/LoaderDefault/Loader';
import NxLoaderTheme1 from './Components/Loader/LoaderTheme1/Loader';

import NxActionTabs from './Components/ActionTab/ActionTabDefault/ActionTab';

import NxTitleTabs from './Components/TitleTab/TitleTabDefault/TitleTab';
import TitleTabTheme1 from './Components/TitleTab/TitleTabTheme1/TitleTab';

import NxGridLists from './Components/Grid/Grid';
import NxGridListT1 from './Components/Grid/GridTheme1/Grid';

import NxRightDrawers from './Components/RightDrawer/RightDrawerDefault/RightDrawer';
import NxRightDrawerTheme1 from './Components/RightDrawer/RightDrawerTheme1/RightDrawer';

import NxCards from './Components/Card/CardDefault/Card';
import CardTheme1 from './Components/Card/CardTheme1/Card';

import { Configs } from './Configs';

let envs = 'default'
let state = false

export const NxCustomTab = ({ data = [{ name: '', Components: () => <div></div> }], initialKey = 0 }) => {
  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
  }, []);
  if (themes == 'default') {
    return <NxCustomTabs data={data} initialKey={initialKey} />
  } else {
    return <NxCustomTabsTheme1 data={data} initialKey={initialKey} />
  }
};

export const NxConfigs = (theme) => {
  // console.log('---------->', theme)
  if (theme == 'theme1') {
    envs = 'theme1';
    state = !state
  } else {
    state = !state
    envs = 'default'
  }
}

export const NxBreadcrumb = ({ data = [{ name: '', onClick: () => { } }], className = '' }) => {
  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
  }, []);
  if (themes == 'default') {
    return <NxBreadcrumbs data={data} className={className} />
  } else {
    return <BreadCrumbTheme1 data={data} className={className} />
  }
};


export const NxFooter = ({
  companyName = '© 2021 Altascio Technologies Inc.',
  className = '',
  onTermsClick = () => { },
  onNxappClick = () => { },
  ExtraContent = []
}) => {
  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
  }, []);
  if (themes == 'default') {
    return <NxFooters companyName={companyName} onTermsClick={onTermsClick} onNxappClick={onNxappClick} className={className} ExtraContent={ExtraContent} />
  } else {
    return <NxFooterTheme1 companyName={companyName} onTermsClick={onTermsClick} onNxappClick={onNxappClick} className={className} ExtraContent={ExtraContent} />
  }
};

export const NxHeader = ({
  title =
  Configs.companyName,
  email = '',
  className = '',
  DrawerIcon = () => { return <div /> },
  PortalHerderTitle = () => { return <div /> },
  onNotifyClick = () => { },
  onAvatarClick = () => { },
  rightElements = [{ elements: () => { return <div /> } }],
}) => {
  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
  }, []);
  if (themes == 'default') {
    return <NxHeaders title={title} email={email} className={className} onNotifyClick={onNotifyClick} onAvatarClick={onAvatarClick} PortalHerderTitle={PortalHerderTitle} DrawerIcon={DrawerIcon} rightElements={rightElements} />
  } else {
    return <NxHeaderTheme1 title={title} email={email} className={className} onNotifyClick={onNotifyClick} onAvatarClick={onAvatarClick} PortalHerderTitle={PortalHerderTitle} DrawerIcon={DrawerIcon} rightElements={rightElements} />
  }
};


export const NxLoader = ({
  state = false,
  color = 'secondary'
}) => {
  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
  }, []);
  if (themes == 'default') {
    return <NxLoaders color={color} state={state} />
  } else {
    return <NxLoaderTheme1 color={color} state={state} />
  }
};

export const NxActionTab = ({
  enableNewEntry = false,
  onNewEntryClick = () => { },
  onColumnsClick = () => { },
  onRefreshClick = () => { },
  onDownloadClick = () => { },
  exportValue = [],
  fileName = '',
  data = [],
  className = ''
}) => {
  return <NxActionTabs enableNewEntry={enableNewEntry} onNewEntryClick={onNewEntryClick} onColumnsClick={onColumnsClick} onRefreshClick={onRefreshClick} onDownloadClick={onDownloadClick} exportValue={exportValue} fileName={fileName} data={data} className={className} />
}

export const NxTitleTab = ({
  title = '',
  onCloseClick = () => { },
  className = '',
  enableClose = true,
  contentClass = '',
  rightSideClass = '',
  contentData = [
    {
      Content: () => {
        return <div />
      }
    }
  ]
}) => {
  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
  }, []);
  if (themes == 'default') {
    return <NxTitleTabs title={title} onCloseClick={onCloseClick} className={className} enableClose={enableClose} contentClass={contentClass} contentData={contentData} leftSideClass={rightSideClass} />
  } else {
    return <TitleTabTheme1 title={title} onCloseClick={onCloseClick} className={className} enableClose={enableClose} contentClass={contentClass} contentData={contentData} leftSideClass={rightSideClass} />
  }
}

export const NxGridList = ({
  header = [
    {
      name: '',
      sort: false,
      search: false
    }
  ],
  data = [
    {
      sendItem: '',
      data: ['']
    }
  ],
  className = '',
  rowClick = (e) => { },
  onSortClick = (e) => { },
  onChangeText = (e) => { },
  searchValue = '',
  placeholder = 'Search...',
  enableSearch = true,
  showShowing = true,
  enableAutoPagination = true,
  autoSearch = true
}) => {
  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
    // console.log(envs);
  }, []);
  // console.log("-> table -- ",themes);
  if (themes == 'default') {
    return <NxGridLists header={header} data={data} className={className} rowClick={rowClick} onSortClick={onSortClick} />
  } else if (themes == 'theme1') {
    return <NxGridListT1
      header={header}
      data={data}
      className={className}
      rowClick={rowClick}
      onSortClick={onSortClick}
      enableAutoPagination={enableAutoPagination}
      onChangeText={onChangeText}
      searchValue={searchValue}
      placeholder={placeholder}
      enableSearch={enableSearch}
      autoSearch={autoSearch}
      showShowing={showShowing}
    />
  }
}

export const NxRightDrawer = ({
  show = false,
  onClose = () => { },
  childClass = '',
  className = '',
  drawer1ClasssName = '',
  drawer2ClasssName = '',
  drawer3ClasssName = '',
  ondrawer1Close = () => { },
  ondrawer2Close = () => { },
  drawerContent = () => <div />,
  drawer1 = false,
  drawer1Content = () => <div />,
  drawer2 = false,
  drawer2Content = () => <div />,
}) => {

  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
  }, []);
  if (themes == 'default') {
    return <NxRightDrawers
      className={className}
      show={show}
      onClose={onClose}
      drawer1ClasssName={drawer1ClasssName}
      drawer2ClasssName={drawer2ClasssName}
      drawer3ClasssName={drawer3ClasssName}
      childClass={childClass}
      ondrawer1Close={ondrawer1Close}
      drawerContent={drawerContent}
      ondrawer2Close={ondrawer2Close}
      drawer1={drawer1}
      drawer2={drawer2}
      drawer1Content={drawer1Content}
      drawer2Content={drawer2Content}
    />
    // return <NxFooters companyName={companyName} onTermsClick={onTermsClick} onNxappClick={onNxappClick} className={className} ExtraContent={ExtraContent} />
  } else {
    return <NxRightDrawerTheme1
      show={show}
      className={className}
      onClose={onClose}
      drawer1ClasssName={drawer1ClasssName}
      drawer2ClasssName={drawer2ClasssName}
      drawer3ClasssName={drawer3ClasssName}
      childClass={childClass}
      ondrawer1Close={ondrawer1Close}
      drawerContent={drawerContent}
      ondrawer2Close={ondrawer2Close}
      drawer1={drawer1}
      drawer2={drawer2}
      drawer1Content={drawer1Content}
      drawer2Content={drawer2Content}
    />
    // return <NxFooterTheme1 companyName={companyName} onTermsClick={onTermsClick} onNxappClick={onNxappClick} className={className} ExtraContent={ExtraContent} />
  }
}


export const NxCard = ({
  className = '',
  datas = [{
    title: "",
    data: [{
      image: '', heding: '', info: '', onClick: () => { }
    }]
  }]
}) => {
  const [themes, setThemes] = useState(envs)
  useEffect(() => {
    setThemes(envs);
  }, []);
  if (themes == 'default') {
    return <NxCards datas={datas} className={className} />
  } else {
    return <CardTheme1 datas={datas} className={className} />
  }
}