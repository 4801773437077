import React, { useState } from 'react';
import Styles from './Header.css';
// import { faBars, faUserCircle, faBell } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Configs } from '../../../Configs';

export default function NxHeaderTheme1({
    title = Configs.companyName,
    email = '',
    DrawerIcon = () => { return <div /> },
    PortalHerderTitle = () => { return <div /> },
    className = '',
    onNotifyClick = () => { },
    onAvatarClick = () => { },
    rightElements = [{ elements: () => { return <div /> } }],
}) {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    return (
        <div>
            <div className={`${Styles.topheader} ${className}`}>
                <div className={`row ${Styles.contain}`}>
                    <div className={`col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 ${Styles.leftheader}`}>
                        <div className={`${Styles.icns} text-center`} onClick={showDrawer} >
                            <DrawerIcon />
                        </div>
                    </div>
                    <div className={`col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ${Styles.centerHeader}`}>
                        <PortalHerderTitle />
                    </div>

                    <div className={`col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 ${Styles.rightheader}`}>
                        {rightElements.map((item, index) => {
                            return <div key={index} className={`${Styles.icns}`}>
                                {item.elements()}
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className={`${Styles.pos}`}>
                -
            </div>
        </div>
    )
}
