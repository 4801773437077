import React, { useEffect, useState } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import Styles from './Loader.css'

export default function NxLoaderTheme1({ 
    state = false,
    color = 'secondary'
}) {
    
    const [load, setLoad] = useState(state);
    useEffect(() => {
        setLoad(state)
    },[state]);

    if (state == false) {
        return (
            <div />
        )
    } else {
        return (
            <div className={Styles.nxloader}>
                <LinearProgress  color={color} className={Styles.loaderClass} />
            </div>
        )
    }
}