import React from 'react';
import Styles from './CustomTabs.css';
import { Tabs } from 'antd';

export default function NxCustomTabsTheme1({ data = [{ name: '', Components: () => <div></div> }], initialKey = 0 }) {

    const { TabPane } = Tabs;

    function callback(key) {
        console.log(key);
    }
    return (
        <div className={`${Styles.customtabMain}`}>
            <div className={`${Styles.customtab} nex-tabs container-flud mt-3`}>
                <Tabs defaultActiveKey={initialKey} onChange={callback}>
                    {data.map((item, index) => {
                        return <TabPane tab={item.name} key={index}>
                            {item.Components()}
                        </TabPane>
                    })}
                </Tabs>
            </div>
        </div>
    )
}
