import React, { useState, useEffect } from "react";
import Styles from './RightDrawer.css'


export default function NxRightDrawer({
    show = false,
    onClose = () => { },
    childClass = '',
    drawer1Size = '30%',
    className = '',
    drawer1ClasssName = '',
    drawer2ClasssName = '',
    drawer3ClasssName = '',
    ondrawer1Close = () => { },
    ondrawer2Close = () => { },
    drawerContent = () => <div />,
    drawer1 = false,
    drawer1Content = () => <div />,
    drawer2 = false,
    drawer2Content = () => <div />,
}) {


    const exitFunction = () => {
        if (tab2 == true) {
            ondrawer2Close();
        } else if (tab1 == true) {
            console.log('111111111111111');
            ondrawer1Close();
        } else {
            onClose();
        }
    }

    const [hide, setHide] = useState(show)
    const [tab1, setTab1] = useState(drawer1);

    useEffect(() => {
        setHide(show)
    }, [show])

    useEffect(() => {
        setTab1(drawer1)
    }, [drawer1]);

    const [tab2, setTab2] = useState(drawer2);
    useEffect(() => {
        setTab2(drawer2)
    }, [drawer2]);

    if (hide == false) {
        return (
            <div />
        )
    } else {
        return (
            <div className={`${Styles.nxrightsidepopupbar} ${className}`}>
                <div className={Styles.nxexcess} onClick={exitFunction} />
                {tab2 == true && <div className={`${childClass} ${Styles.nxrightsidechild} ${drawer3ClasssName}`}>
                    {drawer2Content()}
                </div>}
                {tab1 == true && <div className={`${childClass} ${Styles.nxrightsidechild} ${drawer2ClasssName}`}>
                    {drawer1Content()}
                </div>}
                <div className={`${childClass} ${Styles.nxrightsidechild} ${drawer1ClasssName}`}>
                    {drawerContent()}
                </div>
            </div>
        )
    }
}