import React, { useEffect, useState } from 'react';
import Styles from  './Grid.css';
import {BsArrowDown, BsArrowUp} from 'react-icons/bs';

export default function NxGridList({
    header = [
        {
            name: '',
            sort: false
        }
    ],
    data = [
        {
            sendItem: '',
            data: ['']
        }
    ],
    className = '',
    rowClick = (e) => { },
    onSortClick = (e) => {},
}) {
    const [datas, setDatas] = useState();
    const [rows, setRow] = useState(data);
    const [sortsArr, setSortsArr] = useState({}) // true for assending and false for desending
    function sortTable(index, vals) {
        let srt = rows;
        rows.sort((a, b) => {
            if ( a.data[index] < b.data[index] ){
                if(vals == true) {
                    return -1;
                } else {
                    return 1
                }
            }
            if ( a.data[index] > b.data[index] ){
                if(vals == true) {
                    return 1;
                } else {
                    return -1;
                }
            }
            return 0;
        });
        let val = rows.map((item, i) => {
            return <tr key={i} className={Styles.tabledata} onClick={() => {
                rowClick(item.sendItem)
            }}>
                {item.data.map((it, ind) => 
                    { 
                        return <td key={ind} className={Styles.tabledatas}>{it}</td> 
                    }
                )}
            </tr>
        })
        setRow(rows);
        setDatas(val)
    }
    useEffect(() => {
        setRow(data);
    },[data]);
    useEffect(() => {
        let val = rows.map((item, i) => {
            return <tr key={i} className={Styles.tabledata} onClick={() => {
                rowClick(item.sendItem)
            }}>
                {item.data.map((it, ind) => 
                    { 
                        return <td key={ind} className={Styles.tabledatas}>{it}</td> 
                    }
                )}
            </tr>
        })
        setDatas(val)
    },[rows])
    return (
        <div className={`${Styles.gridlist} table-responsive table-wrapper-scroll-y my-custom-scrollbar ${className}`}>
            <table id="table-nxs" className="table table-hover" >
                <thead>
                    <tr className={Styles.thds}>
                        {header.map((item, i) => {
                            return <th key={i} scope="col" className={Styles.tableheader}>
                                <div className={Styles.nxdivtableheader}>
                                    <span>{item.name}</span> {item.sort == true && <span className={Styles.sort} onClick={() => { 
                                        // onSortClick(item.name)
                                        let vals;
                                        if(!sortsArr[i]) {
                                            setSortsArr({[i]: true});
                                            vals = true
                                        } else {
                                            if (sortsArr[i] == true) {
                                                setSortsArr({[i]: false});
                                                vals = false
                                            } else {
                                                setSortsArr({[i]: true});
                                                vals = true
                                            }
                                        }
                                        sortTable(i, vals)
                                    }}>
                                        {/* <img src={sort} className={Styles.nxtablesorticon} width="15" /> */}
                                        <div className={Styles.nxtablesorticon}>
                                            <BsArrowUp style={{marginRight: -3}} color={sortsArr && sortsArr[i] == false ? 'grey' : sortsArr[i] == true ? 'black' : 'grey'} size='15'  />
                                            <BsArrowDown style={{marginLeft: -3}} color={sortsArr && sortsArr[i] == true ? 'grey' : sortsArr[i] == false ? 'black' : 'grey'} size='15' />
                                        </div>
                                        {/* <Sort className={Styles.nxtablesorticon} width="4" height="4" /> */}
                                    </span>}
                                </div>
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {datas}
                </tbody>
            </table>
        </div>
    )
}