import React from 'react';
import Style from './BreadCrumb.css';
import { Breadcrumb } from 'antd';

export default function BreadCrumbTheme1({ data = [{ name: '', onClick: () => { } }], className = '' }) {

    let { Item } = Breadcrumb;

    return (
        <div className={`${Style.breadcrum} ${className}`}>
            <Breadcrumb separator="|">
                {data.map((item, index) => {
                    return <Item onClick={item.onClick} key={index} separator="|" >
                        <a style={{ color: "black" }}> {item.name} </a>
                    </Item>
                })}
            </Breadcrumb>
        </div>
    )
}
