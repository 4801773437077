import { Space } from 'antd';
import React from 'react';
import Styles from './Footer.css';

export default function NxFooter({
    companyName='© 2021 Altascio Technologies Inc.',
    className= '',
    onTermsClick = () => {

    },
    onNxappClick = () => {

    },
    ExtraContent = []
}) {
    return (
        <div className={`${Styles.footer} ${className}`}>
            <Space>
                <span> {companyName} </span>
                {ExtraContent.map((item, index) => <span key={index} onClick={item.onClick} className={Styles.points}> {item.name} </span>)}
            </Space>
        </div>
    )
}